<template>
    <div>
        <div class="main-wrap"></div>
        <div class="content-wrap">
            <div class="loginWrap">
                <div class="login-header">
                    <img src="@/assets/img/icon/gear2.svg" alt="">
                    <div>Administrator</div>
                </div>
                <div class="inputWrap">
                    <div class="input-account input-box">
                        <label for="">Account</label>
                        <input type="text" v-model="form.account"  placeholder="Enter your username">
                    </div>
                    <div class="input-password input-box">
                        <label for="">Password</label>
                        <input type="password" v-model="form.password" placeholder="Enter your password" v-on:keydown="keydownEvent">
                    </div>
                </div>
                <div class="login-btn">
                    <div @click="loginAccount">Login</div>
                </div>
            </div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
@import '@/assets/scss/_variables.scss';
.content-wrap{
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 50%;
    left: 50%;
    min-height: 100vh;
    transform: translate(-50%,-50%);
    justify-content: space-between;
    align-items: center;
    display: flex;
    @media (max-width:900px) {
        width: 90%;
        left: 50%;
    }
    .logoWrap{
        width: 40%;
        height: auto;
        left: 14%;
        position: absolute;
        margin-top: 0;
        top: 50%;
        transform: translateY(-50%);
        @media (max-width:600px){
            width: 95%;
            height: 25vh;
            margin-top: 0;
        }
        img{
            width: 44%;
            @media (max-width:600px){
                width: 20%;
            }
        }
        h3{
            font-size: $font*25;
            color: #fff;
            line-height: 2;
            letter-spacing: 3px;
            @media (max-width: 600px) {
                font-size: $font*50;
            }
        }
    }
    .loginWrap{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 25%;
        min-width: 325px;
        height: 65%;
        background-color: #fff;
        border-radius: 15px;
        position: absolute;
        right: 4%;
        .login-header{
            width: 65%;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 20%;
            // background-color: gold;
        }
        .inputWrap{
            width: 85%;
            height: 50%;
            display: flex;
            flex-direction: column;
            align-items: center; 
            // background-color: greenyellow;
            .input-box{
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                label{
                    align-self: flex-start;
                    margin-bottom: 10px;
                }
                input{
                    align-self: flex-start;
                    width: 100%;
                    height: 40%;
                    padding: 0 10px;
                    &:focus{
                        border: 1px solid $purple;
                    }
                }
            }
        }
        .login-btn{
            width: 85%;
            height: 10%;
            border-radius: 7px;
            line-height: 2.5;
            background-color: $purple;
            color: #fff;
            cursor: pointer;
        }
    }
}
.main-wrap{
    width: 100%;
    height: 100vh;
    background:$purple;
}
.bgImg{
    background-repeat: no-repeat;
    position: absolute;
    width: 30vw;
    height: 20vw;
    background-image: url('~@/assets/img/bgImg.png');
    background-size: contain;
    bottom: 0;
    left: 0;
}
</style>
<script>
import {login, logout} from '@/api';
import { mapActions } from 'vuex';
export default {
    data(){
        return{
            form:{
                account:'',
                password:'',
            }
        }
    },
    methods:{
        ...mapActions(['setLogin']),
        async loginAccount(){
            if(this.form.account!=''&& this.form.password!=''){
                // let res = await login(this.form);
                let vm = this;
                let res = await login(this.form).catch(function (error) {
                    if (error.response) {
                        if(error.response.status==422||error.response.status==404){
                            vm.$message({
                                showClose: true,
                                message:`${error.response.data.message}`,
                                type: 'warning',
                                duration: 1500,
                            });
                        }
                    }
                });
                if(!res.data) return;
                let auth = await this.setLogin(res.data.data);
                switch (auth) {
                    case 'admin':
                        this.loginSuccessfulMsg(()=>{
                            this.$router.push({name:'Admin'});
                        });
                        break;
                    default:
                        logout();
                        this.loginFailedMsg();
                        break;
                }
            }
            else{
                this.loginFailedMsg();
            }
        },
        loginSuccessfulMsg(event){
            this.$message({
                showClose: true,
                message: `You've logged in successfully!`,
                type: 'success',
                duration: 1000,
                onClose: function(){
                    event();
                }
            });
        },
        loginFailedMsg(){
            this.$message({
                showClose: true,
                message: `There's no relevant admin account`,
                type: 'error',
                duration: 1000,
            });
        },
        keydownEvent(e){
            if(e.keyCode===13){
                this.loginAccount();
            }
        }
    } 
}
</script>